//styles
import styles from './Footer.module.css'

function Footer () {
    const s = styles

    return (
        <div className={s.mainCon}>
            <p>⋆ Desing & build by Carlos A. Jimenez Romero ⋆</p>
        </div>
    )
}

export default Footer;